/* Jakarta Font */
@font-face {
	font-family: 'Jakarta';
	src: url('./assets/fonts/PlusJakartaSans-Medium.ttf');
}

html {
	height: 100%;
	width: 100%;
}

* {
	font-family: 'Jakarta', sans-serif;
	margin: 0;
	padding: 0;
}

body {
	background-color: #f2f2f2 !important;
	height: 100%;
}

a:link {
	text-decoration: none;
}

/* override navbar fluid styles */
#navbarContainer {
	padding-left: 0px !important;
}

#root {
	height: 100%;
}

.tippy-popper {
	pointer-events: auto !important;
}

.tippy-tooltip {
	padding: 0px 2px;
}

.modal-backdrop {
	z-index: 1040 !important;
}
